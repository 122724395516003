<template>
  <section class="section">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="wrapp-section-title section-title-center">     
            <h2 class="section-title">{{title}}</h2>
          </div>
        </div>
        <div class="col">
          <p>
            <span>We respect your privacy and are committed to protecting it. We provide this Privacy Statement to inform you of our Privacy Policy and practices and of the choices you can make about the way your information is collected on-line and how that information is used. We've structured our websites so that, in general, you can visit us on the web without identifying yourself or revealing any personal information. We make this notice readily available on our home page and at the bottom of every web page.</span>
          </p>
          <h4>Sites covered by this Privacy Statement</h4>
          <p>
            <span>This Privacy Statement applies to all ObjectPower owned websites and domains. ObjectPower websites may provide links to third-party websites for your convenience and information. If you access those links, you will leave the ObjectPower website. ObjectPower does not control those sites or their privacy practices, which may differ from our own. We do not endorse or make any representations about third-party websites. The personal data you choose to give to unrelated third parties is not covered by the ObjectPower Privacy Statement. We encourage you to review the privacy policy of any company before submitting your personal information. Some third-party companies may choose to share their personal data with ObjectPower that sharing is governed by that third-party company's privacy policy.</span>
          </p>
          <h4>Types of information we collect</h4>
          <p>
            <span>This Privacy Statement covers personal information, non-personal data collection and aggregate reporting. Personal information is information that is associated with your name or personal identity. ObjectPower uses personal information to understand better your needs and interests and to provide you with better service. Once you choose to provide us with personal information, you can be assured it will be used only to support your customer relationship with ObjectPower. We take seriously the trust you place in us. ObjectPower will not sell, rent or lease your personal information to others. To personalize our websites, services or communications and improve our products and services, we may also ask you to provide us with information regarding your personal or professional interests, demographics, and experiences with our products or services. Providing this additional information is optional.</span>
            <span>Non-personal information is data about usage and service operation that is not associated with a specific personal identity. ObjectPower collects and analyses non-personal information to evaluate how visitors use the ObjectPower websites.</span>
            <span>ObjectPower also places ads on other websites that may use cookies.</span>
          </p>
          <h4>Children's privacy</h4>
          <p>
            <span>ObjectPower is committed to protecting the privacy needs of children and we encourage parents and guardians to take an active role in their children's on-line activities and interests. ObjectPower does not knowingly collect information from children under the age of 13 and ObjectPower does not target its websites to children under 13.</span>
          </p>
          <h4>How we use your information</h4>
          <p>
            <span>ObjectPower uses your personal information to provide you with services and to help us better understand your needs and interests. Specifically, we use your information to help you complete a transaction or order, to communicate with you, to provide service and support, to update you on services and benefits, to personalise promotional offers and to personalise some ObjectPower websites. Occasionally we may also use your information to contact you for market research regarding ObjectPower products or services. We will give you the opportunity to choose your privacy preferences regarding such communications. Credit card information is used only for payment processing and fraud prevention. Sensitive personal information is collected only to facilitate our financial service providers credit decisions and will be shared with them only with your permission. Credit card information and sensitive personal data are not used for any other purpose by our financial services providers or ObjectPower and will not be kept longer than necessary for providing the services, unless you ask us to retain your credit card information for future purchases. Personal data collected on-line may also be combined with information you provide ObjectPower through other sources such as product registration, call centres or public events such as trade shows or seminars. Non-personal data is aggregated for reporting about ObjectPower website usability, performance and effectiveness. It is used to improve the customer experience, usability and site content.</span>
          </p>
          <h4>Who we share your information with</h4>
          <p>
            <span>ObjectPower will not sell, rent or lease your personal information to others. ObjectPower will not share your personal information with third parties except in responding to your requests for products or services. Your permission will be requested when you submit your information. ObjectPower shares customer information across ObjectPower-owned business entities and companies working on our behalf, but only as described above in ?How we use your information.? ObjectPower contracts with third-party service providers and suppliers to deliver complete products, services and customer solutions described in ?How we use your information.? Suppliers and service providers are required to keep confidential the information received on behalf of ObjectPower and may not use it for any purpose other than to carry out the services they are performing for ObjectPower. These service providers may change or we may contract with additional service providers to better accommodate our customers. ObjectPower will not share personal information with any other third parties without your permission, unless required by law enforcement action, subpoena, or local law. ObjectPower or its related entities could merge with or be acquired by another business entity or some or all of their respective assets could be acquired. If such a combination or acquisition occurs, ObjectPower will make every reasonable effort to notify you in the event we share with the merging or acquiring entity some or all of your personal information to continue serving you.</span>
          </p>
          <h4>Your choices</h4>
          <p>
            <span>ObjectPower gives you the choice of receiving a variety of information that complements our products and services. You can subscribe to receive certain product- and service-specific information and ObjectPower-wide marketing communications. ObjectPower-wide communications may include new product information, special offers, or an invitation to participate in market research. We give you a choice regarding delivery of ObjectPower-wide communications by postal mail, e-mail and telephone. You can make or change your choices at the data collection point or through Microsoft Passport (Microsoft?s proprietary online customer registration tool). Please refer to section 7 for further instructions. We will make every effort to honor your preferences.</span>
          </p>
          <h4>Your privacy preferences and opting out</h4>
          <p>
            <span>Non-subscription services ObjectPower will not use your personal information for a different purpose than the one stated when you provided your data. Examples of unrelated uses include sending communications about special offers, product information, and marketing messages. You can, however, choose to receive these types of non-subscription communications by following the instructions below. Please specify your privacy preferences by writing to the ObjectPower Privacy Office ? be sure to provide your name, e-mail and postal address in all correspondence. Subscription services To opt-out of receiving communications you have expressly requested (such as e-mail newsletters, software updates, etc.), use any of the following methods: Select the e-mail 'opt-out' or 'unsubscribe' link, or follow the opt-out instructions included in each subscription communication Return to the Web page(s) where you originally registered your preferences and follow the opt-out instructions Write to the ObjectPower Privacy Office ? be sure to provide your name, e-mail and postal address, and relevant information about your ObjectPower subscriptions and registration.</span>
          </p>
          <h4>Your information and third-party companies</h4>
          <p>
            <span>Certain ObjectPower services are linked with those from unrelated third-party companies, some which offer you the option to share with both ObjectPower and the third party personal data you provide. Examples include the ability to register software products from multiple vendors from a single ObjectPower web page, to request communications from ObjectPower marketing or solution partners, or to enable order completion through a reseller. We will not share your personal information with those third-party companies unless you make that choice.</span>
          </p>
          <h4>Access to and accuracy of your information</h4>
          <p>
            <span>ObjectPower strives to keep your personal information accurate. We have implemented technology, management processes and policies to maintain customer data accuracy. We will provide you with access to your information, including making reasonable effort to provide you with online access and the opportunity to change your information. To protect your privacy and security, we will also take reasonable steps to verify your identity, such as a password and user ID, before granting access to your data. Certain areas of ObjectPower?s websites may limit access to specific individuals through the use of passwords and other personal identifiers.</span>
          </p>
          <h4>Keeping your information secure</h4>
          <p>
            <span>ObjectPower is committed to protecting the information you provide us. To prevent unauthorized access or disclosure, to maintain data accuracy, and to ensure the appropriate use of the information, ObjectPower has in place appropriate physical and managerial procedures to safeguard the information we collect. We use Secure Sockets Layer (SSL) encryption when collecting or transferring sensitive data such as credit card information. SSL encryption is designed to make the information unreadable by anyone but us. This security measure is working when you see either the symbol of an unbroken key or closed lock (depending on your browser) on the bottom of your browser window.</span>
          </p>
          <h4>Changes to this Statement</h4>
          <p>
            <span>If there are updates to the terms of our on-line Privacy Statement, we will post those changes and update the revision date in this document, so you will always know what information we collect on-line, how we use it, and what choices you have.</span>
          </p>
          <p>Updated: 15th March 2020</p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Privacy Policy",
  data() {
    return {
      title: "Privacy Policy"
    };
  }
};
</script>

<style>
</style>